<template>
  <div class="grid-box m-4 p-4 flex flex-col">
    <h2 class="text-left">{{ $t('activitySuggestions.bestActivities') }}</h2>
    <div class="flex justify-between mt-3 mx-4">
      <div class="flex justify-center items-center font-bold text-3xl pr-5
           cursor-pointer" @click="scrollOverview('category', 'left')">
        <i class="fas fa-angle-left"></i>
      </div>
      <div id="category-overview"
           class="mt-3 flex w-full overflow-scroll no-scrollbar mx-4">
        <div v-for="(category, index) in $store.getters['activity/categories']"
             :id="`category-${index}`"
             :key="category.name"
             class="flex flex-col mx-2 flex-shrink-0 relative">
          <div v-if="category.requires_premium && !$store.getters['account/has_premium']"
               @click="$store.commit('common/setShowPremiumModal', { status: true });"
               class="absolute inset-x-0 inset-y-0 flex justify-center items-center text-3xl cursor-pointer">
            <div class="absolute inset-x-0 inset-y-0 opacity-50 bg-white">
            </div>
            <i class="fas fa-lock z-10"></i>
          </div>
          <img :class="{'selected': category === selected_category, 'unselected': category !== selected_category}"
               :src="$store.getters['common/baseURL'] + category.image"
               class="rounded-xl object-cover p-2 cursor-pointer"
               loading="lazy"
               style="height: 150px; width: 150px"
               @click="select_category(category)"/>
          <div class="mt-1 text-center">
            {{ category.name }}
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center font-bold text-3xl pl-5 cursor-pointer"
           @click="scrollOverview('category', 'right')">
        <i class="fas fa-angle-right"></i>
      </div>
    </div>

  </div>
  <div class="flex justify-between mt-3 mx-4">
    <div class="flex justify-center items-center font-bold text-3xl pr-5
         cursor-pointer" @click="scrollOverview('activity', 'left')">
      <i class="fas fa-angle-left"></i>
    </div>
    <div id="activity-overview" class="m-4 overflow-scroll no-scrollbar flex">
      <div v-for="(activity, index) in filtered_activities"
           :key="activity.name"
           :id="`activity-${index}`"
           class="flex-shrink-0 flex flex-col mr-4 relative rounded p-2 bg-white overflow-scroll no-scrollbar"
           style="height: 250px; width: 220px">
        <div :class="{
                                         'text-blue': $store.getters['account/likes_activity'](activity),
                                         'text-transparent': !$store.getters['account/likes_activity'](activity)
                                         }"
             class="absolute text-xl cursor-pointer bg-white flex justify-center items-center"
             style="top: 115px; right: 15px; height: 34px; width: 34px; border-radius: 50%"
             @click="like(activity)">
          <i class="fa-solid fa-heart stroke" style="margin-top: 2px;"></i>
        </div>
        <img :src="$store.getters['common/baseURL'] + activity.image"
             class="rounded-xl object-cover flex-shrink-0"
             loading="lazy"
             style="width: 200px; height: 150px"
        />
        <div class="w-full flex mt-1 font-bold">
          <div class="w-4/5 overflow-scroll no-scrollbar">{{ activity.name }}</div>
          <div class="w-1/5">{{ activity.score }}%</div>
        </div>
        <hr class="mt-3"/>
        <div v-for="(weight, trait) in activity.trait_weights" :key="trait" class="flex justify-between">
          <div>{{ localized_trait(trait) }}</div>
          <div class="flex justify-center items-center">
            <div v-for="i in weight[1]" :key="i">
              <div v-if="weight[0] === 1" class="text-blue">
                <i class="fas fa-arrow-up"></i>
              </div>
              <div v-else class="text-red-500">
                <i class="fas fa-arrow-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center font-bold text-3xl pl-5 cursor-pointer"
         @click="scrollOverview('activity', 'right')">
      <i class="fas fa-angle-right"></i>
    </div>
  </div>
  <GetPremium v-if="$store.getters['common/showPremiumModal']"></GetPremium>
</template>

<style>
.stroke path {
  stroke: rgb(113 134 187);
  stroke-width: 35;
}

.selected {
  border: 1px solid rgb(113 134 187);
}

.unselected {
  border: 1px transparent;
}
</style>

<script>
import axios from '../axios';
import GetPremium from "@/components/GetPremium";

export default {
  components: {GetPremium},
  data() {
    return {
      filtered_activities: this.$store.getters['activity/activities'],
      selected_category: null,
      timeout_id: null,
      activity_map: this.$store.getters['activity/activity_map']
    }
  },
  methods: {
    async like(activity) {
      if (this.timeout_id === null) {
        this.timeout_id = setTimeout(function () {
          this.timeout_id = null;
        }.bind(this), 500);
      } else {
        return;
      }
      if (this.$store.getters['account/likes_activity'](activity)) {
        this.$store.commit('account/undo_activity_like', {
          activity
        });
        await axios.post('activity/like/', {
          activity_id: activity.id,
          undo: true
        });
        return;
      }

      this.$store.commit('account/like_activity', {
        activity
      });
      await axios.post('activity/like/', {
        activity_id: activity.id
      });
    },
    select_category(category) {
      this.selected_category = category;
      this.filtered_activities = [];
      let filtered_activities = [];
      for (const activity of this.$store.getters['activity/activities']) {
        if (category.activities.indexOf(activity.id) !== -1) {
          filtered_activities.push(activity);
        }
      }

      filtered_activities.sort((a, b) => {
        return b.score - a.score
      });
      this.filtered_activities = filtered_activities;
    },
    localized_trait(trait) {
      let trait_en = this.$store.getters['character/categories'][trait];
      trait_en = trait_en[0].toUpperCase() + trait_en.substring(1);

      if (this.$store.getters['account/selected_locale'] === 'de') {
        return this.$store.getters['character/categories_de'][trait_en];
      }

      return trait_en;
    },
    scrollOverview(type, direction) {
      const div = document.getElementById(`${type}-overview`);
      const categoryDiv1 = document.getElementById(`${type}-0`);
      const categoryDiv2 = document.getElementById(`${type}-1`);
      const width = categoryDiv2.getBoundingClientRect().right - categoryDiv1.getBoundingClientRect().right;
      const scrollAmount = direction === 'left' ? -width : width;
      div.scrollBy(scrollAmount, 0);
    }
  },
  mounted() {
    if(this.$store.getters['account/has_premium']) {
      this.select_category(this.$store.getters['activity/categories'][0]);
    }
    else {
      let i = 0;
      for(const category of this.$store.getters['activity/categories']) {
        if(!category.requires_premium) {
          this.select_category(category);
          document.getElementById(`category-${i}`).scrollIntoView();
          break;
        }
        ++i;
      }
    }
  }
}
</script>