<template>
  <div id="premium-modal" class="fixed bg-white px-10 py-5 shadow-lg rounded-xl hidden"
       style="top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 101; width: 95%; max-width: 500px;
       max-height: 500px">
    <div class="flex justify-between items-center mb-4">
      <img class="h-12" src="../assets/wiggle3.png"/>
      <h1>{{ $t('getPremium.title') }}</h1>
      <img class="h-12" src="../assets/wiggle2.png"/>
      <div class="cursor-pointer" @click="close()">
        <i class="fas fa-times text-3xl"></i>
      </div>
    </div>
    <hr/>
    <p class='text-center'>{{ $t('getPremium.subtitle') }}</p>
    <ul class="mt-2 font-bold list-disc">
      <li>{{ $t('getPremium.benefits.activities') }}</li>
      <li>{{ $t('getPremium.benefits.matchCharacters') }}</li>
      <li>{{ $t('getPremium.benefits.accurateTests')}}</li>
    </ul>

    <div id="paypal-button-container" class="mt-4 flex justify-center">
      <div v-if="waiting" class="text-center">
        {{ $t('getPremium.waiting') }}
        <i class="fas fa-spinner fa-spin ml-2 text-xl"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: "GetPremium",
  data() {
    return {
      waiting: false,
    }
  },
  methods: {
    close() {
      this.$store.commit('common/setShowPremiumModal', {status: false});
    },
    init() {
      axios.get(location.protocol + '//' + location.hostname + ':8000/payment/handle_paypal/', {
        params: {
          'language_code': this.$store.getters['account/selected_language']
        }
      }).then(response => {
        this.waiting = false;
        document.getElementById('paypal-button-container').innerHTML = response.data;
      });
    }
  },
  mounted() {
    const modal = document.getElementById('premium-modal');
    document.body.appendChild(modal);
    document.getElementById('mycharacter').classList.toggle('blurry');
    document.getElementById('premium-modal').classList.toggle('hidden');
    document.getElementById('blackout').classList.toggle('hidden');
    this.init();
  },
  unmounted() {
    document.getElementById('mycharacter').classList.toggle('blurry');
    document.getElementById('blackout').classList.toggle('hidden');
  }
}
</script>